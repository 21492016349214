@import url(https://fonts.googleapis.com/css2?family=PT+Serif&display=swap);
/*prevent the body from overflowing. This ensures that our parent grid container will retain full control of the entire browser viewport. There should be nothing outside of our parent*/
* {
  text-decoration: none;
  font-family: 'PT Serif', serif;
  font-weight: lighter;
}
body {
  margin: auto 0;
  width: 100%;
  background-color:  rgba(63, 191, 127, 0.71);
}
.bold-font{
  font-weight: 900;
}
/*hide any overflow to ensure nothing leaks outside of the .content element that would disturb the parent’s layout.*/
.content {
  height: 100vh;
  width: 100vw;
  display: grid;
  grid-template-columns: auto;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
  overflow: hidden;
  overflow-y: scroll;
}
.header-wrap {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto 17%;
  -webkit-align-items: center;
          align-items: center;
  overflow: hidden;
  width: 100%;
  height: 200px;
  background-color: #E0E0E0;
}
.headerTop {
  display: grid;
  grid-template-columns: 2fr 6fr;
  grid-template-columns: 1fr 4fr;
  grid-template-rows: auto;
  padding: 0px 0px 0px 20px;
}
.headerBottom {
  display: grid;
  grid-template-columns: auto auto auto auto auto auto;
  grid-template-columns: repeat(auto-fill, minmax(auto, 135px));
  grid-template-rows: auto;
  justify-items: end;
  list-style: none;
  font-size: 19px;
  padding: 0px 40px 0px 0px;
  margin-left:520px;
}
.nav-list{
  display: grid;
  justify-items: center;
}
.nav-list > a, .dropdownLink2 {
  color: black;
}
/* Added Navbar dropdown */
.dropdownCont{
  display: grid;
  margin-left:90px;
  width:100%;
}
.dropdown-select {
  display: grid;
  grid-template-rows: auto;
  cursor: pointer;
  width:100%;
}
.dropdown {
  display: grid;
  position: fixed;
}
.dropdown-list {
  visibility: hidden;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(auto, 120px));
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 20px;
  background-color: white;
  width: 115px;
  height:0px;
  padding: 10px 0px 20px 20px;
  margin: 0px 0px 0px 0px;
  border-radius:10px;
}
.dropdownLink2{
  display: grid;
  grid-template-columns: 70px auto;
  grid-template-rows: auto;
}
.dropdownLink2:hover .dropdown-list {
  opacity: 1;
  visibility: visible; 
  height:250px;
}
.dropdown-list__item {
  padding: 0px 0px 0px 0px;
  border-bottom: 0.1px solid black;
  color: #333333;
  font-size: 17px;
  display: grid;
  text-decoration: none;
  border-bottom: 1px solid #dddddd;
}
.homePage{
  display: grid;
}
#welcome {
  display: grid;
  grid-template-columns: auto;
  justify-items: center;
}
#welcome > h2 {
  font-size: 35px;
}
#welcomeMsg {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
  width: 100%;
}
#project-heading {
  display: grid;
  width: 70%;
}
#project-heading > h2{
  font-size:20px;
}
.about {
  display: grid;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
}
.about > img {
  object-fit: contain;
  -webkit-align-self: center;
          align-self: center;
  justify-self: center;
  width: 600px;
  height: 400px;
}
.about > p {
  font-size: 20px;
  width: 70%;
}
.contactPage{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto;
  justify-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  width:70%;
}
.contactDesc{
  width:100%;
  font-size:20px;
  margin-top: -1px; 
}
.privacyNotice{
  font-size:20px;
}
.formDiv{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  margin-top:25px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-content: center;
          align-content: center;
  width:80%;
}
.form {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto auto auto auto auto;
  grid-gap:10px;
  border: 1px solid; 
  border-radius:7px;
  padding:20px 7px 7px 7px;
  background-color:  rgba(63, 191, 127, 0.8);
  background-color:#95A69E;
}
.doublebox{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  grid-gap:22px;
}
.doublebox2{
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: auto;
  -webkit-align-items:center;
          align-items:center;
}
.doublebox2Messages{
  display: grid;
  grid-template-rows: auto auto;
  grid-template-columns: auto;
  justify-items: center;
}
.doublebox2MessagesCont{
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: auto auto auto auto;
}
.singlebox {
  display: grid;
}
.singlebox2{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto;
}
.doublebox > input, .doublebox2 > input, .singlebox > input{
  height:25px;
  width:200px;
}
.doublebox > input, .doublebox2 > input, .singlebox > input, .singlebox2 > textarea {
  border-radius: 0px;
  outline: none;
}
.singlebox2 > textarea {
  height:86px;
}
.doublebox2Messages > label, .doublebox2MessagesCont > label, .singlebox2 > label{
  font-size:15px;
}
.submitcontactbutton {
  display: grid;
  align-self: center;
  justify-self: center;
  width:140px;
  height: 33px;
  border-radius: 200px;
  font-size: 20px;
  color: black;
  background-color: #BCBCBC;
}
.FAQPage{
  display: grid;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
}
.questionAnswerCont{
  display: grid;
  justify-items: center;
  width:100%;
}
.question{
  width:90%;
  font-size:23px;
  margin-top:-1px;
}
.answer{
  font-size:20px;
  margin-top: -13px;
  width:80%;
}
.ratesPage{
  display: grid;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
}
.ratesPage > h2{
  margin-top: -1px;
}
.ratesContent{
  width: 70%;
  font-size: 20px;
}
footer {
  text-align: center;
  font-size: 16px;
  margin: 0px 10px 0px 10px;
}
#contact {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: end;
          align-items: end;
  width: 71.5%;
  padding: 0px 0px 0px 0px;
  padding-top:43px;
}
#connect-links {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto auto; 
  /* justify-items: center; */
  list-style: none;
  width: 100%;
  font-size: 20px;
  /* margin-right: 15px; */
}
#connect-links > li{
  margin-left: -30px; 
}
.servicesPage{
  display: grid;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  width:100%;
}
.servicesTop{
  margin: 0px 70px 30px 79px;
  font-size:20px;
  width:70%;
}
.servicesSingleCont{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 17% auto;
  justify-items: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-align-content: center;
          align-content: center;
  -webkit-justify-content: center;
          justify-content: center;
  padding:0px 0px 12px 0px;
  width:100%;
  padding-bottom:33px
}
.servicesSingleCont > h2{
  font-size:28px;
}
.servicesSingleCont2{
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto;
  -webkit-align-items: center;
          align-items: center;
  justify-items: center;
}
.servicesSingleImg{
  width: 500px;
  height:340px;
  border-radius: 6px;
  justify-self: end;
}
.servicesSingleAbout{
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  -webkit-align-items:center;
          align-items:center;
  width:90%;
}
.servicesSingleAbout > h2{
  font-size:19px;
  width:85%;
  justify-self: start;
}
@media (max-width:1387px){
  .headerBottom {
    margin-left:400px;
    font-size: 17px;
  }
}
@media (max-width:1268px){
  .headerBottom {
    margin-left:315px;
  }
}
@media (max-width: 1180px) {
  .headerBottom {
    grid-template-columns: auto auto auto auto auto auto;
    justify-items: center;
    width:100%;
    margin-left:0px;
  }
  .dropdownCont{
    margin-left:30px;
  }
}

@media (max-width: 1080px) {
  .servicesSingleImg{
    width: 335px;
    height:250px;
  }
  .servicesSingleCont > h2{
    margin-bottom: -20px;
  }
  .servicesSingleCont2{
    grid-template-columns: auto;
    grid-template-rows: auto auto;
  }
  .servicesSingleAbout{
    justify-items: center;
    width:100%;
  }
  .servicesSingleImg, .servicesSingleAbout > h2{
    justify-self: center;
  }
}

@media (max-width: 820px) {
  #project-heading, .about > p, .ratesContent, .servicesTop, .question {
    width: 85%;
  }
  .contactDesc{
    width:95%;
  }
  #contact{
    width:100%;
    padding-right:13px
  }
  .headerBottom {
    grid-template-columns: repeat(auto-fill, minmax(auto, 120px));
    padding: 0px 0px 0px 0px;
    margin: 0px 0px 0px 0px;
    padding-left:8px;
    font-size:15px;
    margin-left:0px;
  }
}

@media (max-width: 740px) {
  .headerBottom {
    grid-template-columns: repeat(auto-fill, minmax(auto, 110px));
    font-size:13px;
  }
  .dropdownCont{
    margin-left:0px;
  }
  .dropdownLink2{
    grid-template-columns: 48px auto;
  }
}

@media (max-width: 680px) {
  .headerBottom {
    grid-template-columns: repeat(auto-fill, minmax(auto, 95px));
  }
}

@media (max-width: 575px) {
  .headerBottom {
    grid-template-columns: repeat(auto-fill, minmax(auto, 85px));
    font-size:13px;
  }
  .about > img {
    width: 360px;
    height: 350px;
  }
}

@media (max-width: 530px) {
  .headerBottom {
    grid-template-columns: repeat(auto-fill, minmax(auto, 75px));
    font-size:13px;
  }
  .formDiv{
    width:100%;
  }
  .contactDesc{
    font-size:16px;
  }
  .doublebox > input, .doublebox2 > input, .singlebox > input{
    width:145px;
  }
}

@media (max-width: 450px) {
  .headerBottom {
    grid-template-columns: repeat(auto-fill, minmax(auto, 60px));
    font-size:13px;
  }
}


.logoImg {
  display:grid;
  justify-self:end;
  cursor: pointer;
  height: 171px;
  border-radius: 6px;
  width:100%;
  object-fit: contain;
}
.stockImg{
  background-image:url(/static/media/banner-pref.072cdec6.jpg);
  display:grid;
  justify-self:center;
  border-radius: 6px;
  background-size:100% 100%;
  object-fit: contain; 
  width:94%;
  height:171px;
  max-width: 950px;
}
@media (max-width: 720px) {
  .servicesTop{
    margin: 0px 20px 0px 28px;
    font-size:16px;
  }
}
@media (max-width: 650px) {
  .header-wrap {
    height:138.5px;
  }
  .headerTop{
    margin-top: -25px;
  }
  .headerBottom {
    -webkit-align-self: start;
            align-self: start;
    margin-top: -25px;
  }
  .stockImg{
    -webkit-align-self: center;
            align-self: center;
    height:120px;
  } 
  .about > p, #project-heading > h2, .ratesContent, .answer, .servicesSingleAbout > h2, .privacyNotice {
    font-size: 16px;
  }
  #welcome > h2{
    font-size:26px;
  }
  .question {
    font-size: 18px;
    margin-right:10px;
  }
  #connect-links{
    font-size:14.3px;
  }
}
@media (max-width: 530px) {
  .headerTop {
    padding: 0px 0px 0px 10px;
  }
}
@media(max-width: 501px){
  .stockImg{
    height:99px;
  } 
}

